<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="space-between" class="font-weight-black">
        <v-col cols="12" sm="11">
          <v-breadcrumbs :items="breadcrumbs" divider="/" v-if="!omiteBreadcrumbs">
            <template v-slot:item="props">
              <v-breadcrumbs-item
                :disabled="props.item.disabled"
                :class="{
                  'ApuracaoContrato_breadcrumbs-item': true,
                  'ApuracaoContrato_breadcrumbs-item_disabled': props.item.disabled,
                }"
                @click.native="navegarBreadcrumbs(props.item)">
                {{ props.item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right" v-if="false">
          <v-badge
              bordered
              color="orange"
              icon="mdi-lock"
              overlap
              v-show="!abrirFiltro"
              v-if="habilitarIconBadge">
              <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
          </v-badge>
          <v-icon v-else v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <apuracao-contrato-filtro
      v-show="false"
      ref="filtros"
      v-model="abrirFiltro"
      :aplicar-filtro-no-carregamento="aplicarFiltroNoCarregamento"
      @ApuracaoContratoFiltro__AplicaFiltros="aplicarFiltros"
      @ApuracaoContratoFiltro__filtroCarregado="aplicarFiltroNoCarregamento = false"
      @FiltroRapido__HabilitaIconBadge="habilitaIconBadge">
    </apuracao-contrato-filtro>

    <transition name="slide-fade"
                mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import ApuracaoContratoFiltro from './ApuracaoContratoFiltro';
import PlanejamentoContratoListCards from '../planejamento-contrato/list/PlanejamentoContratoListCards';
import ApuracaoContratoControleBreadcrumbs from './ApuracaoContratoControleBreadcrumbs';

export default {
  name: 'ApuracaoContratoAntigo',
  components: {
    PlanejamentoContratoListCards,
    ApuracaoContratoFiltro,
  },
  mixins: [ApuracaoContratoControleBreadcrumbs],
  data() {
    return {
      abrirFiltro: false,
      omiteBreadcrumbs: false,
      aplicarFiltroNoCarregamento: true,
      filtros: {},
      habilitarIconBadge: false,
    };
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canAccessPage',
    ]),
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoContrato',
      'setFiltroApuracaoContratoNivel1',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    aplicarFiltros(filtrosAplicados) {
      const filtros = {
        ...filtrosAplicados,
      };
      this.filtros = filtros;
      this.setFiltroApuracaoContrato(filtros);
      this.setFiltroApuracaoContratoNivel1(null);
    },
    habilitaIconBadge(valor) {
      this.habilitarIconBadge = valor;
    },
  },
  beforeMount() {
    this.setFiltroApuracaoContrato(null);
    this.setFiltroApuracaoContratoNivel1(null);
    this.setFiltroApuracaoContratoNivel2(null);

    const { origem } = this.$route.params;
    if (origem) {
      this.omiteBreadcrumbs = true;
    }
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>

<style lang="scss">
  .ApuracaoContrato_breadcrumbs-item_disabled {
    color: grey;
    pointer-events: none;
  }
  .ApuracaoContrato_breadcrumbs-item {
    color: grey;
    cursor: pointer;
  }
  .ApuracaoContrato_breadcrumbs-item:hover {
    color: #3f3f3f;
  }
</style>
