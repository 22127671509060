<template>
  <filtro-rapido v-model="abrirFiltro"
    ref="filtrosRapidos"
    class="ApuracaoContratoFiltro--filtro"
    :campos-formulario="camposFormulario"
    :metadados="metadadosAgrupado"
    :ordenacao-campos="ordenacaoCampos"
    :filtro-padrao="filtroPadrao"
    :ignora-inativos="false"
    :entidade-workspace="entidadeWorkspace"
    :aplicar-filtro-no-carregamento="aplicarFiltroNoCarregamento"
    @FiltroRapido__AplicaFiltros="aplicarFiltros"
    @FiltroRapido__HabilitaIconBadge="(value) => $emit('FiltroRapido__HabilitaIconBadge', value)">
  </filtro-rapido>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '../../shared-components/filtro-rapido/FiltroRapido';
import ApuracaoContratoFiltroCampos from './ApuracaoContratoFiltroCampos';

export default {
  name: 'ApuracaoContratoFiltro',
  props: {
    value: false,
    aplicarFiltroNoCarregamento: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [
    ApuracaoContratoFiltroCampos,
  ],
  components: {
    FiltroRapido,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      configuracaoResource: this.$api.apuracaoConfiguracao(this.$resource),

      abrirFiltro: false,
      ordenacaoCampos: null,
      entidadeWorkspace: 'apuracao_contrato',
      filtroPadrao: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getContratoMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isUsuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    metadadosAgrupado() {
      if (!this.getAcaoMetadado || !this.getContratoMetadado) {
        return {};
      }

      const metadados = { ...this.getContratoMetadado };
      metadados.dicionario = { ...this.getAcaoMetadado.dicionario };
      Object.assign(metadados.dicionario, this.getContratoMetadado.dicionario);

      metadados.mapaCamposDinamicos = { ...this.getAcaoMetadado.mapaCamposDinamicos };
      Object.assign(metadados.mapaCamposDinamicos, this.getContratoMetadado.mapaCamposDinamicos);

      metadados.mapaEntidades = { ...this.getAcaoMetadado.mapaEntidades };
      Object.assign(metadados.mapaEntidades, this.getContratoMetadado.mapaEntidades);

      if (metadados.cabecalho) {
        metadados.cabecalho.concat(...this.getAcaoMetadado.cabecalho);
      } else {
        metadados.cabecalho = this.getAcaoMetadado.cabecalho;
      }
      return metadados;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
      'setContratoMetadado',
    ]),
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro({ tipo: 'CONTRATO' })
        .then((res) => {
          this.ordenacaoCampos = res.data;
          if (this.isUsuarioFornecedor) {
            this.ordenacaoCampos = res.data.filter((c) => c.campo !== 'unidadenegocio');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    aplicarFiltros(filtrosAplicados) {
      filtrosAplicados.is_apuracao = true;
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (this.aplicarFiltroNoCarregamento) {
        this.$emit('ApuracaoContratoFiltro__filtroCarregado');
      }
      this.$emit('ApuracaoContratoFiltro__AplicaFiltros', filtrosAplicados);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados && (filtrosAplicados.data_inicio_apuracao
        || filtrosAplicados.data_fim_apuracao)) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_apuracao && !filtrosAplicados.data_fim_apuracao)
          || (!filtrosAplicados.data_inicio_apuracao && filtrosAplicados.data_fim_apuracao)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    preencherFiltroPadrao() {
      this.filtroPadrao.data_inicio_apuracao = this.moment().startOf('year').format('YYYY-MM-DD');
      this.filtroPadrao.data_fim_apuracao = this.moment().endOf('year').format('YYYY-MM-DD');
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.carregaCamposFiltros();
  },
  beforeMount() {
    this.preencherFiltroPadrao();
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
    this.setContratoMetadado({
      resource: this.metadadoResource.definicaoContrato,
    });
  },
};
</script>
<style lang="scss">
  .ApuracaoContratoFiltro--filtro {
    width: 480px!important;
  }

</style>
